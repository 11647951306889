import * as React from 'react';
import { Alert, Snackbar, Slide } from '@mui/material';
import PropTypes from 'prop-types';

function SnackbarAlert(props) {
  const { open, message, onCloseHandler, severity } = props;
  const Transition = Slide;
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={onCloseHandler}
        autoHideDuration={3000}
        TransitionComponent={Transition}
      >
        <Alert severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

SnackbarAlert.propType = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onCloseHandler: PropTypes.func,
  severity: PropTypes.string,
};

export default SnackbarAlert;
