import * as React from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function InformationTabs(data) {
  const { characterData } = data;
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Contact Details" />
          <Tab label="Address" />
          <Tab label="Membership" />
        </Tabs>
      </Box>
      <TabPanel value={selectedValue} index={0}>
        <Box sx={{ width: '100%', maxWidth: 500 }}>
          <Typography variant="subtitle2" gutterBottom>
            Phone: {characterData?.phone}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Cell: {characterData?.cell}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Email: {characterData?.email}
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={selectedValue} index={1}>
        <Typography variant="subtitle2" gutterBottom>
          City: {characterData?.location.city}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Country: {characterData?.location.country}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Post Code: {characterData?.location.postcode}
        </Typography>
      </TabPanel>
      <TabPanel value={selectedValue} index={2}>
        <Typography variant="subtitle2" gutterBottom>
          Membership Duration: {characterData?.registered.age}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Registration Date: {characterData?.registered.date}
        </Typography>
      </TabPanel>
    </Box>
  );
}

InformationTabs.propType = {
  data: PropTypes.shape({
    gender: PropTypes.string,
    name: PropTypes.shape({
      title: PropTypes.string,
      first: PropTypes.string,
      last: PropTypes.string,
    }),
    location: {
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.number,
    },
    email: PropTypes.string,
    dob: {
      date: PropTypes.string,
      age: PropTypes.number,
    },
    phone: PropTypes.string,
    cell: PropTypes.string,
    picture: PropTypes.shape({
      large: PropTypes.string,
      medium: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
    nat: PropTypes.string,
    id: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};
