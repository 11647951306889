import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

function CircularIndeterminate() {
  return (
    <Box
      sx={{ display: 'flex' }}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
}

export default CircularIndeterminate;
