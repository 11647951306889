import * as React from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import AvatarsGrid from './components/AvatarsGrid';
import AvatarDetail from './components/AvatarDetail';
import ROUTES from './constants/routes';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={ROUTES.CHARACTERS_HOME}
            element={<AvatarsGrid />}
          />
          <Route
            path={ROUTES.CHARACTERS_DETAIL}
            element={<AvatarDetail />}
          />
          <Route
            path="*"
            element={
              <Navigate replace to={ROUTES.CHARACTERS_HOME} />
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
