import React from 'react';
import { Box, IconButton, Grid } from '@mui/material/';
import { ArrowBack } from '@mui/icons-material/';
import { useNavigate } from 'react-router';
import AvatarCharacter from '../AvatarCharacter';
import TabPanel from '../TabPanel';
import useStore from '../../hooks/useStore';

function AvatarDetails() {
  const selectedAvatar = useStore((state) => state.selectedAvatar);
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, padding: '1.2rem' }}>
      <IconButton
        color="inherit"
        edge="start"
        size="large"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBack />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <AvatarCharacter
            isClickable={false}
            characterData={selectedAvatar}
          />
        </Grid>
        <Grid item xs={8}>
          <TabPanel characterData={selectedAvatar} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AvatarDetails;
