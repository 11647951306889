import { create } from 'zustand';

const useStore = create((set) => ({
  page: 0,
  nextPage: () => set((state) => ({ page: state.page + 1 })),
  backPage: () => set((state) => ({ page: state.page - 1 })),

  selectedAvatar: {},
  setSelectedAvatar: (avatar) =>
    set(() => ({ selectedAvatar: avatar })),

  avatarsData: [],
  setAvatars: (newAvatars) =>
    set(() => ({ avatarsData: newAvatars })),
}));

export default useStore;
