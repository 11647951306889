import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Avatar,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStore from '../../hooks/useStore';

function AvatarCharacter(data) {
  const { characterData, isClickable } = data;
  const setSelectedAvatar = useStore(
    (state) => state.setSelectedAvatar,
  );

  const navigate = useNavigate();
  return (
    <Card>
      <CardActionArea
        onClick={() => {
          if (isClickable) {
            setSelectedAvatar(characterData);
            navigate(`${characterData?.login?.uuid}`); // API returns null ids for some users, now using uuids
          }
        }}
      >
        <CardContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              alt={`${characterData?.name?.title} ${characterData?.name?.first} thumbnail`}
              src={characterData?.picture?.thumbnail}
            />
            <Typography variant="h5" component="div">
              {`${characterData?.name?.title} ${characterData?.name?.first} ${characterData?.name?.last}`}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Gender: {characterData?.gender}
            </Typography>
            <Typography variant="body2">
              Nationality: {characterData?.nat}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

AvatarCharacter.propType = {
  data: PropTypes.shape({
    gender: PropTypes.string,
    name: PropTypes.shape({
      title: PropTypes.string,
      first: PropTypes.string,
      last: PropTypes.string,
    }),
    location: {
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.number,
    },
    email: PropTypes.string,
    dob: {
      date: PropTypes.string,
      age: PropTypes.number,
    },
    phone: PropTypes.string,
    cell: PropTypes.string,
    picture: PropTypes.shape({
      large: PropTypes.string,
      medium: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
    nat: PropTypes.string,
    id: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  isClickable: PropTypes.bool,
};

export default AvatarCharacter;
