import React, { useEffect, useState } from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import AvatarCharacter from '../AvatarCharacter';
import API_URL from '../../constants/restEndpoints';
import useStore from '../../hooks/useStore';
import SnackbarAlert from '../Snackbar';
import CircularIndeterminate from '../Loader';

async function fetchAvatars(page = 0) {
  const { data } = await axios.get(`${API_URL}&page=${page}`);
  return data;
}

function AvatarsGrid() {
  const page = useStore((state) => state.page);
  const nextPage = useStore((state) => state.nextPage);
  const backPage = useStore((state) => state.backPage);
  const setAvatars = useStore((state) => state.setAvatars);

  const [open, setOpen] = useState(false);
  const { status, data, error } = useQuery(
    ['avatars', page],
    () => fetchAvatars(page),
    {
      keepPreviousData: true,
      staleTime: Infinity, // Stale time is set to infinity because the free API I have used doesn't return same results for a
      // given page number. So instead of getting updated copy of same data, new data is returned. To prevent this
      // I stopped the behavior by not staling data.
    },
  );

  useEffect(() => {
    if (status === 'error') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [status]);

  useEffect(() => {
    setAvatars(data?.results);
  }, [data?.results]);

  return (
    <>
      {status === 'loading' ? (
        <CircularIndeterminate />
      ) : (
        <>
          <Grid
            sx={{
              padding: '1rem',
            }}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data?.results.map((characterData) => (
              <Grid
                item
                xs={2}
                sm={4}
                md={4}
                key={characterData?.login?.uuid}
              >
                <AvatarCharacter
                  characterData={characterData}
                  isClickable
                />
              </Grid>
            ))}
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{
              marginTop: '1rem',
            }}
          >
            <Button
              variant="contained"
              disabled={page < 1}
              onClick={backPage}
            >
              Back
            </Button>
            <Button variant="contained" onClick={nextPage}>
              Next
            </Button>
          </Stack>
        </>
      )}
      <SnackbarAlert
        open={open}
        message={error?.message}
        severity="error"
        onCloseHandler={() => setOpen(false)}
      />
    </>
  );
}

export default AvatarsGrid;
